import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { CommonService } from '@app/services/common.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	menuActive: string = 'home';

	constructor(private viewportScroller: ViewportScroller, private commonService: CommonService) { 
		this.commonService.onMenuActuve().subscribe(menu => {
			if (menu) {				
				this.menuActive = menu;				
							
			}	
		})
		this.viewportScroller.setOffset([0, 46]);

	}

	ngOnInit(): void {
	}

	goto(elementId: string) {
		this.viewportScroller.scrollToAnchor(elementId);
	}

}
