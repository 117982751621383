import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENDPOINTS } from '@app/constants';
import { Observable, Subject, throwError } from 'rxjs';
import { environment } from '@environments/environment';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

	private menuActive = new Subject<string>();

  	constructor(private http: HttpClient) { }
	
	setMenuActive(menu: string) {        
        this.menuActive.next(menu);
    }

    onMenuActuve(): Observable<any> {
    	return this.menuActive.asObservable();
	}  

	getComments(): Observable<any> {
		return this.http.get<any>(environment.apiStaticJson + "/assets/json/comments.json")
			.pipe(map((response: any) => {                                                                
				return response;
		}))
	}


}
