
<div id="home" #home>
    <div fxLayout="row" class="container">
        <div fxFlex="50%" fxFlex.lt-md="60%" fxFlex.lt-sm="70%" fxFlex.lt-sm="100%">
            <div fxLayout="column" fxLayoutGap="20px">
                <div class="subtitle">{{'subtitle' | translate}}</div>
                <div class="title">{{'title' | translate }}</div>
                <div class="cutline">{{'cutline' | translate }}</div>
                <div class="cutline">{{'cutline2' | translate }}</div>
                <!--<button fxFlexOffset="10px" mat-flat-button class="btn" id="btn-contact-1" (click)="goto('contact')" type="button">{{'contact' | translate}} &nbsp; <img src="assets/images/blue-arrow-sm.png"></button>-->

            </div>
        </div>
        <div fxFlex="50%" fxFlex.lt-md="40%" fxFlex.lt-sm="30%" fxFlex.lt-sm="100%">

        </div>
    </div>
</div>
<div class="bkg">
    <div id="product" #product>

        <div fxLayout="row" fxLayout.lt-md="column" style="margin-bottom: 30px" class="container product-cards">
            <div fxFlex="60" >
                <div fxLayout="column" fxLayoutGap="20px">
                    <div class="subtitle">{{'actualChallenge' | translate}}</div>
                    <div class="title">{{'ecosystemDigital' | translate }}</div>
                    <div class="cutline fs">{{'ecosystemDigitalCutline' | translate }}</div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutGap="20px">
                <div fxLayout="row" fxLayoutGap="20px">
                    <img width="120" class="first-top" src="assets/icons/MDflecha01.svg" >
                    <img width="120"  class="sec-top" src="assets/icons/MDflecha01.svg" >
               </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutAlign="center center">
                <mat-card class="digital-card" >
                    <div class="img" ><img width="65" src="assets/icons/Icono_carta_01.svg"></div>
                    <mat-card-content>
                        <div class="description" style="color: white;">{{'newTecnology' | translate }}</div>
                    </mat-card-content>
                </mat-card>
                 <mat-card class="digital-card" >
                    <div class="img" ><img width="65" src="assets/icons/Icono_carta_02.svg"></div>
                    <mat-card-content>
                        <div class="description">{{'newPlayers' | translate }}</div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="digital-card" >
                    <div class="img margin" ><img width="65" src="assets/icons/Icono_carta_03.svg"></div>
                    <mat-card-content>
                        <div class="description">{{'platformTI' | translate }}</div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="digital-card" >
                    <div class="img margin" ><img width="65" src="assets/icons/Icono_carta_04.svg"></div>
                    <mat-card-content>
                        <div class="description">{{'errorPayment' | translate }}</div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="digital-card" >
                    <div class="img" ><img width="65" src="assets/icons/Icono_carta_05.svg"></div>
                    <mat-card-content>
                        <div class="description">{{'bajaClient' | translate }}</div>
                    </mat-card-content>
                </mat-card>
                </div>
                <div fxLayout="row" fxLayoutGap="20px">
                    <img width="120" class="first-bot" src="assets/icons/MDflecha02.svg" >
                    <img width="120" class="sec-bot" src="assets/icons/MDflecha02.svg" >
                </div>
            </div>

        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" class="container">
            <div fxFlex="60" >
                <div fxLayout="column" fxLayoutGap="20px">
                    <div class="subtitle">{{'ownProposal' | translate}}</div>
                    <div class="title">{{'ownProposalTitle' | translate }}</div>
                    <div class="cutline fs">{{'ownProposalCutline' | translate }}</div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-around" fxLayoutGap.lt-md="30px">
                <div fxLayout="row"  fxLayoutGap="60px" fxHide.lt-md="true">
                    <img width="120" class="next-arrow top" src="assets/icons/MDflecha01.svg" >
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="60px" fxLayoutAlign="center center">
                    <div fxLayout="column" class="card-container">
                        <div class="front" id="first">
                            <div class="badge" fxLayout="column" fxFlexAlign="center center"><img width="25" src="assets/icons/MDicono05.svg"></div>
                            <mat-card class="card">
                                <mat-card-header>
                                    <mat-card-title><b class="title">{{'paymentIntegration' | translate}}</b></mat-card-title>
                                </mat-card-header>
                                <mat-card-actions>
                                    <img class="arrow" width="75" src="assets/icons/MDflecha03-white.svg" >
                                    </mat-card-actions>
                            </mat-card>
                        </div>

                        <div class="back" id="first">
                            <div class="badge" fxLayout="column" fxFlexAlign="center center"><img width="25" src="assets/icons/MDicono05-accent.svg"></div>
                            <mat-card class="card">
                                <mat-card-header>
                                    <mat-card-title><b class="title">{{'paymentIntegration' | translate}}</b></mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                <span class="cutline">
                                    {{'cardTextFirst' | translate}}
                                </span>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div fxLayout="column" class="card-container">
                        <div class="front" id="second">
                            <div class="badge" fxLayout="column" fxFlexAlign="center center"><img width="19" src="assets/icons/MDicono06.svg"></div>
                            <mat-card class="card">
                                <mat-card-header>
                                    <mat-card-title><b class="title"> {{'collectionImprovement' | translate}}</b></mat-card-title>
                                </mat-card-header>
                                <mat-card-actions>
                                    <img class="arrow margin1" width="75" src="assets/icons/MDflecha03-white.svg" >
                                    </mat-card-actions>
                            </mat-card>
                        </div>

                        <div class="back" id="second">
                            <div class="badge" fxLayout="column" fxFlexAlign="center center"><img width="19" src="assets/icons/MDicono06-accent.svg"></div>
                            <mat-card class="card">
                                <mat-card-header>
                                    <mat-card-title><b class="title"> {{'collectionImprovement' | translate}}</b></mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                <span class="cutline">
                                    {{'cardTextSecond' | translate}}</span>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div fxLayout="column" class="card-container">
                        <div class="front" id="third">
                            <div class="badge" fxLayout="column" fxFlexAlign="center center"><img width="23" src="assets/icons/MDicono07.svg"></div>
                            <mat-card class="card">
                                <mat-card-header>
                                    <mat-card-title><b class="title"> {{'reportAndAnalitycs' | translate}}</b></mat-card-title>
                                </mat-card-header>
                                <mat-card-actions>
                                    <img class="arrow margin2" width="75" src="assets/icons/MDflecha03-white.svg" >
                                </mat-card-actions>
                            </mat-card>
                        </div>

                        <div class="back" id="third">
                            <div class="badge" fxLayout="column" fxFlexAlign="center center"><img width="23"src="assets/icons/MDicono07-accent.svg"></div>
                            <mat-card class="card">
                                <mat-card-header>
                                    <mat-card-title><b class="title"> {{'reportAndAnalitycs' | translate}}</b></mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                <span class="cutline">
                                    {{'cardTextThird' | translate}} </span>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div fxLayout="column" class="card-container">
                        <div class="front" id="fourth">
                            <div class="badge" fxLayout="column" fxFlexAlign="center center"><img width="23" src="assets/icons/MDicono08.svg"></div>
                            <mat-card class="card">
                                <mat-card-header>
                                    <mat-card-title><b class="title"> {{'moreProductivity' | translate}}</b></mat-card-title>
                                </mat-card-header>
                                <mat-card-actions>
                                    <img class="arrow" width="75" src="assets/icons/MDflecha03-white.svg" >
                                </mat-card-actions>
                            </mat-card>
                        </div>

                        <div class="back" id="fourth">
                            <div class="badge" fxLayout="column" fxFlexAlign="center center"><img width="23"src="assets/icons/MDicono08-accent.svg"></div>
                            <mat-card class="card">
                                <mat-card-header>
                                    <mat-card-title><b class="title"> {{'moreProductivity' | translate}}</b></mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                <span class="cutline">
                                    {{'cardTextFourth' | translate}} </span>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </div>
                <div fxLayout="row"fxLayoutGap="60px" fxHide.lt-md="true">
                    <img width="120" class="next-arrow bot" src="assets/icons/MDflecha02.svg" >
                </div>
            </div>
        </div>

        <div fxLayout="column" class="container margin" >
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="60px">
                <div fxFlex="40">
                    <div fxLayout="column" fxLayoutGap="20px">
                        <div class="subtitle">{{'automaticOperations' | translate}}</div>
                        <div class="title">{{'knowMoodebit' | translate }}</div>
                        <div class="cutline fs">{{'knowMoodebitText' | translate }}</div>
                        <div class="cutline fs">{{'knowMoodebitDesc' | translate }}</div>
                        <!-- <button fxFlexOffset="10px" mat-flat-button  class="btn" type="button">{{'contact' | translate}} &nbsp; <img src="assets/images/blue-arrow-sm.png"></button>             -->
                        <!--<button fxFlexOffset="10px" mat-flat-button (click)="goto('contact')" class="btn-contact" type="button">{{'contact' | translate}} &nbsp; <img src="assets/images/blue-arrow-sm.png"></button>-->

                    </div>
                </div>
                <div fxFlex="60">
                    <!-- <img width="600" class="video-box" src="assets/images/videoimg.PNG"> -->
                </div>
            </div>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" class="container product-cards">
            <div fxFlex="50" >
                <div fxLayout="column" fxLayoutGap="20px">
                    <div class="subtitle">{{'benefits' | translate}}</div>
                    <div class="title">{{'incrementsProfits' | translate }}</div>
                    <div class="cutline fs">{{'incrementProfitsDesc' | translate }}</div>
                </div>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column"  fxLayoutGap="30px" class="product-card-group" fxLayoutAlign="center center">
                <mat-card class="product-card" >
                    <div class="img" ><img width="60" src="assets/icons/l03.png"></div>
                    <mat-card-content>
                        <div class="cardtitle">{{'indexIncrement' | translate }}</div>
                        <mat-divider class="divider"></mat-divider>
                        <div class="cutline fs">{{'indexIncrementText' | translate }}</div>
                    </mat-card-content>
                  </mat-card>
                  <mat-card class="product-card">
                    <div class="img" ><img width="69" src="assets/icons/l02.png"> </div>
                    <mat-card-content>
                        <div class="cardtitle">{{'incrementFails' | translate }}</div>
                        <mat-divider class="divider"></mat-divider>
                        <div class="cutline fs">{{'incrementFailsText' | translate }}</div>
                    </mat-card-content>
                  </mat-card>
                  <mat-card class="product-card" >
                    <div class="img" > <img width="65" src="assets/icons/l04.png"></div>
                    <mat-card-content>
                        <div class="cardtitle">{{'incrementPayment' | translate }}</div>
                        <mat-divider style="margin-top: 21%;" class="divider"></mat-divider>
                        <div class="cutline fs">{{'incrementPaymentText' | translate }}</div>
                    </mat-card-content>
                  </mat-card>
                  <mat-card class="product-card" >
                    <div class="img" ><img width="95" src="assets/icons/l01.png"></div>
                    <mat-card-content>
                        <div class="cardtitle">{{'eficientPayment' | translate }}</div>
                        <mat-divider class="divider" style="margin-top: 21%;"></mat-divider>
                        <div class="cutline fs">{{'eficientPaymentText' | translate }}</div>
                    </mat-card-content>
                  </mat-card>
                  <mat-card class="product-card">
                    <div class="img"><img width="95" src="assets/icons/icono_mejor_posicionamiento.svg"></div>
                    <mat-card-content>
                        <div class="cardtitle">{{'bestPositioning' | translate }}</div>
                        <mat-divider class="divider"></mat-divider>
                        <div class="cutline fs">{{'informationComparative' | translate }}</div>
                    </mat-card-content>
                  </mat-card>
            </div>
        </div>
        

    </div>
    <div id="aboutus" #aboutus>
        <div class="container">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px">
                <div fxFlex="40%" fxFlex.lt-lg="23%" fxFlex.lt-md="100%"  fxLayout="column" fxLayoutGap="30px">
                    <div class="title">{{'aboutUs' | translate }}</div>
                    <div class="blue">{{'mission' | translate }}</div>
                    <div class="text" fxFlexOffset="-25px">{{'missionText' | translate }}</div>
                    <div class="blue">{{'vision' | translate }}</div>
                    <div class="text" fxFlexOffset="-25px">{{'visionText' | translate }}</div>
                    <div fxFlexOffset="50px" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                        <div fxLayoutAlign="start center">
                            <img src="assets/images/blue-arrow.png">
                        </div>
                        <div class="alliance">{{'alliance' | translate }}</div>
                    </div>
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
                        <img style="width:234px" src="assets/images/Logo_temperies.png">
                    </div>
                </div>
                <div fxFlex="60%" fxFlex.lt-lg="70%" fxFlex.lt-md="100%" fxLayout="column" fxLayoutGap="30px">
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayout.lt-md="column" fxLayoutGap="30px">
                        <div class="card" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center" >
                            <img src="assets/images/avatar-fer.png" style="border-radius: 50%; max-width: 60%; max-height: 60%;" >
                            <div class="name">Fernando Joseph Horigian</div>
                            <div class="subtitle">CEO<br> Bussines development</div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutGap="30px">
                        <div class="card" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
                          <img src="assets/images/nico-avatar.png">
                          <div class="name">Nicolas Villarreal</div>
                          <div class="subtitle">CCO</div>
                      </div>
                      <div class="card" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
                          <img src="assets/images/jorge-avatar.png" style="border-radius: 50%; max-width: 50%; max-height: 50%;">
                          <div class="name">Jorge Carlos Mendiola</div>
                          <div class="subtitle">CTO</div>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutGap="30px">
                        <div class="card" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
                          <img src="assets/images/avatar-vane.png">
                          <div class="name">Vanesa DellAcqua</div>
                          <div class="subtitle">Partners & founders</div>
                      </div>
                      <div class="card" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
                          <img src="assets/images/avatar-guille.png" style="border-radius: 50%; max-width: 50%; max-height: 50%;">
                          <div class="name">Guillermo Belsito</div>
                          <div class="subtitle">Partners & founders</div>
                      </div>
                    </div>


                </div>
            </div>
        </div>
    </div>    
    <div id="contact" #contact>
        <div class="container">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px">
                <div fxFlex="40%" fxFlex.lt-lg="23%" fxFlex.lt-md="100%"  fxLayout="column" fxLayoutGap="30px">
                    <div class="title">Contacto</div>
                    <div class="blue">Email</div>
                    <div class="text" fxFlexOffset="-25px">Si desea contactarse via email haga click <a href="mailto:moodebit@temperies.com">aqui</a> </div>
                    <div class="blue">Telefono</div>
                    <div class="text" fxFlexOffset="-25px">Si desea contactarse via telefonica haga click <a href="https://wa.me/+541130067666">aqui</a></div>
                    <div fxFlexOffset="50px" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                        
                    </div>
                </div>
                  
            </div>
        </div>
    </div>
    
    <div id="footer" name="footer" #footer>
        <div class="container">
            <div fxLayout="column" fxLayoutGap="15px">
                <div *ngIf="formSent" fxLayout="column">
                    <div class="white">{{'thanks' | translate }}</div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div id="comments">
        <div class="container">
            <owl-carousel-o [options]="customOptions">
                <ng-container *ngFor="let c of comments">
                    <ng-template carouselSlide>
                        <div class="inner mat-elevation-z2">
                            <div fxLayout="column" fxLayoutGap="20px">
                                <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center">
                                    <img [src]="c.img" fxFlex="30%" fxFlex.lt-lg="50%">
                                    <div class="info" fxFlex="70%" fxFlex.lt-lg="50%" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px">
                                        <div class="name">{{c.name}}</div>
                                        <div class="charge">{{c.charge}}</div>
                                    </div>
                                </div>
                                <div class="comments">
                                    "{{c.comment}}"
                                </div>
                            </div>

                        </div>
                    </ng-template>
                </ng-container>
              </owl-carousel-o>
        </div>
    </div> -->
    <!-- <div id="partners">
        <div class="container">
            <div fxLayout="column" fxLayoutGap="30px" fxLayoutAlign="center center">
                <div class="title">{{'partners' | translate}}</div>
                <div fxLayout="row" fxLayoutGap="130px" fxLayout.lt-sm="column" fxLayoutGap.lt-md="30px" fxLayoutAlign="center center">
                    <div><img src="assets/images/Logo_metlife.png"></div>
                    <div><img src="assets/images/Logo_prisma.png"></div>
                    <div><img src="assets/images/Logocentralpos.png"></div>
                </div>
            </div>
        </div>
    </div> -->
</div>

