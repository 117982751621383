<div class="footer">
    <!-- <div fxLayout="column" class="container" > 
        <div fxLayout="row" fxLayout.lt-md="column"  fxLayoutAlign.lt-md="center center" fxLayoutGap="60px" class="margin">
            <div fxFlex="20">
                <div fxLayout="column" fxLayoutGap="10px" class="links"  fxLayoutAlign.lt-md="center center">
                    <div class="title text moodebit">{{'mooDebit' | translate}}</div>
                    <div class="text" (click)="goto('product')">{{'product' | translate}}</div>
                    <div class="text" (click)="goto('aboutus')">{{'about' | translate}}</div>
                    <div class="text" (click)="goto('contact')">{{'contactUs' | translate}}</div>
                    <div class="text" (click)="goto('home')">{{'login' | translate}}</div>
                    <a href="#" target="_blank"><div class="text">{{'termsAndConditions' | translate}}</div></a>
                    <a href="#" target="_blank"><div class="text">{{'politics' | translate}}</div></a>
                </div>
            </div>
            <div fxFlex="10" class="a" fxHide.lt-md="true" >
                <div fxLayout="column">
                    <img src="assets/icons/MDflecha03.svg">
                </div>
            </div>
            <div fxFlex="20">
                <div fxLayout="column" fxLayoutGap="10px"  fxLayoutAlign.lt-md="center center">
                    <div class="title text">{{'buenosaires' | translate}}</div>
                    <div class="text">{{'street' | translate}}</div>
                    <div class="text">{{'zipCode' | translate}}</div>
                    <div class="text">{{'contactPhone' | translate}}</div>
                </div>
            </div>
            <div fxFlex="10" class="a" fxHide.lt-md="true" >
                <div fxLayout="column" >
                    <img src="assets/icons/MDflecha03.svg">
                </div>
            </div>
            <div fxFlex="20">
                <div fxLayout="column" fxLayoutGap="10px"  fxLayoutAlign.lt-md="center center">
                    <div class="text title">{{'tandil' | translate}}</div>
                    <div class="text">{{'street' | translate}}</div>
                    <div class="text">{{'zipCode' | translate}}</div>
                    <div class="text">{{'contactPhone' | translate}}</div>
                </div>
            </div>
            <div fxFlex="20">
                <div fxLayout="column">
                  <img width="130" src="assets/images/Data_fiscal.png">
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center" class="social">
        <div fxLayout="row" fxLayoutGap="20px">
                <a href="#" target="_blank"><img width="30" src="assets/icons/linkedin-brands-white.svg"></a>
                <a href="#" target="_blank"><img width="30" src="assets/icons/twitter-brands-white.svg"></a>
                <a href="#" target="_blank"><img width="30" src="assets/icons/instagram-brands-white.svg"></a>
        </div>
        <div class="text">{{'moodebitRights' | translate}}</div>
    </div> -->
    <div class="text">{{'moodebitRights' | translate}}</div>

</div>
