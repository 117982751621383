<div fxFlex="column">    
    <mat-toolbar color="primary">        
        <div class="container header">            
            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
                <button fxShow="true" fxHide.gt-xs="true" mat-icon-button (click)="sidenav.toggle()">
                    <mat-icon>menu</mat-icon>
                </button>  
                <img style="cursor: pointer;" src="assets/images/logo_horizontal.png" alt="Logo" (click)="goto('home')">                                        
                    <div fxShow="true" fxHide.lt-sm="true" fxLayout="row" fxFlex fxLayoutAlign="end center" fxLayoutGap="30px" fxLayoutGap.lt-md="10px">
                        <button mat-flat-button class="btn btn-primary" [ngClass]="menuActive == 'home' ? 'active' : ''" (click)="goto('home')">{{'login' | translate}}</button>
                        <button mat-flat-button class="btn btn-primary" [ngClass]="menuActive == 'product' ? 'active' : ''" (click)="goto('product')">{{'product' | translate}}</button>
                        <button mat-flat-button class="btn btn-primary" [ngClass]="menuActive == 'aboutus' ? 'active' : ''" (click)="goto('aboutus')">{{'aboutUs' | translate}}</button>
                        <button mat-flat-button class="btn btn-primary" [ngClass]="menuActive == 'contact' ? 'active' : ''" (click)="goto('contact')">{{'contact' | translate}}</button>
                    </div>                                    
            </div>
        </div>            
    </mat-toolbar>
  
    <mat-sidenav-container>
      <mat-sidenav #sidenav fxLayout="column">
        <div fxLayout="column" class="header">          
            <button mat-flat-button class="btn btn-primary" [ngClass]="menuActive == 'home' ? 'active' : ''" (click)="sidenav.toggle();goto('home')">{{'login' | translate}}</button>
            <button mat-flat-button class="btn btn-primary" [ngClass]="menuActive == 'product' ? 'active' : ''" (click)="sidenav.toggle();goto('product')">{{'product' | translate}}</button>
            <button mat-flat-button class="btn btn-primary" [ngClass]="menuActive == 'aboutus' ? 'active' : ''" (click)="sidenav.toggle();goto('aboutus')">{{'aboutUs' | translate}}</button>
            <button mat-flat-button class="btn btn-primary" [ngClass]="menuActive == 'contact' ? 'active' : ''" (click)="sidenav.toggle();goto('contact')">{{'contact' | translate}}</button>
        </div>
      </mat-sidenav>
      <mat-sidenav-content>
            <router-outlet ></router-outlet>
            <app-footer></app-footer>
       </mat-sidenav-content>
    </mat-sidenav-container>
  </div>