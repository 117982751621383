import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CommonService } from '@app/services/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private viewportScroller: ViewportScroller, private commonService: CommonService) { }

  ngOnInit(): void {
  }

  goto(elementId: string) {	      
		  this.viewportScroller.scrollToAnchor(elementId);
	}

}
