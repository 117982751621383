import { Component, ElementRef, HostListener, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CommonService } from '@app/services/common.service';
import { ViewportScroller } from '@angular/common';


const areas = 'home,product,aboutus,contact';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	@ViewChildren(areas) sections: QueryList<ElementRef>;
	contactForm: FormGroup;        

	customOptions: OwlOptions = {
		loop: true,		
		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,
		dots: true,
		navSpeed: 700,
		navText: ['', ''],
		responsive: {
			0: {
				items: 1,
				center: true,
		  	},
			580: {
				items: 1,
				margin: 20,
				center: true,
		  	},
		  	940: {
				items: 3,
				margin: 80,
				center: true,
		  	}
		},
		nav: false
	}

	formSent: boolean = false;
	sendingForm: boolean = false;

	autoScroll: false;
	comments: any = []

	constructor(private formBuilder: FormBuilder, private commonService: CommonService, private viewportScroller: ViewportScroller) { 
		this.viewportScroller.setOffset([0, 46]);

	}

	ngOnInit(): void {
		this.formSent = false;
		this.commonService.getComments().subscribe(result => {
			this.comments = result;
		})
		this.contactForm = this.formBuilder.group({			
			name: ['', Validators.required],			
			email: ['', [Validators.required, Validators.email]],
			comments: [''],
			company: [''],
			segment: [''],
			employees: [''],
			country: ['']
		});
	}

	sendEmail() {
		var email = "moodebit@temperies.com";
		var subject = 'Consulta MooDebit';
		let emailBody =
		`Nombre y Apellido: ${this.contactForm.value.name}%0D%0A
		Email: ${this.contactForm.value.email}%0D%0A`
		if(this.contactForm.value.company)
			emailBody += "Nombre Empresa: " + this.contactForm.value.company+"%0D%0A";
		if(this.contactForm.value.segment){
			emailBody += "Rubro: " + this.contactForm.value.segment+"%0D%0A";
		}
		if(this.contactForm.value.employees){
			emailBody += "Cantidad de Empleados: " + this.contactForm.value.employees+"%0D%0A";
		}
		if(this.contactForm.value.country){
			emailBody += "País: " + this.contactForm.value.country+"%0D%0A";
		}
		if(this.contactForm.value.comments){
			emailBody += "Comentario: " + this.contactForm.value.comments+"%0D%0A";
		}
	

		window.location.href = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
	}

	
	goto(elementId: string) {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	get f() { return this.contactForm.controls; }

	onSubmit() {               
		this.sendingForm = true;    
        if (this.contactForm.invalid) {
			this.sendingForm = false;
            return;
		}

		setTimeout(() => {
			this.sendingForm = false;
			this.formSent = true;
		}, 2000);				


	}

	@HostListener('window:scroll', ['$event'])
  	onScroll(event) {

		let activeSection = this.sections.toArray().findIndex(section => isElementInViewport(section.nativeElement));
		if (activeSection == 3 || activeSection == -1) {
			this.commonService.setMenuActive(areas.split(',')[3])
		} else {
			let nextSection = (this.sections.toArray()[activeSection + 1]);
			if (activeSection != -1) {
				var rect = nextSection.nativeElement.getBoundingClientRect();
				if (rect.top < 56) {
					this.commonService.setMenuActive(areas.split(',')[activeSection + 1])
				} else {
					this.commonService.setMenuActive(areas.split(',')[activeSection])
				}
			} 
		}
			
		
  }


}


function isElementInViewport(el) {
	var rect = el.getBoundingClientRect();
	return (
	  rect.bottom >= 0 &&
	  rect.right >= 0 &&
	  rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
	  rect.left <= (window.innerWidth || document.documentElement.clientWidth)
	);
}
